import { useState,useEffect, useRef }  from "react";
import {Container, Row, Col, ListGroup } from 'react-bootstrap';
import "./style.scss";
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import { useLocation} from 'react-router-dom'
import LoadSpinner from './../../components/Loader';
import Videobg from './../../static/img/marketplace.mp4';
import Homelistbg from '../../static/img/homeInner.svg';
import Actionimg1 from '../../static/img/actionimg1.svg';
import Actionimg2 from '../../static/img/actionimg2.svg';
import Opencallbg from '../../static/img/OpenCallBanner.png';
import HomeSlider from './HomeSlider';
import HomeList from './HomeList';
import ActionBox from './ActionBox';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';


function HomePage (){
	const [isLoaded, setIsLoaded] = useState(true);
	const { pathname } = useLocation();
	const [mutedV,videoHandler] = useState(true);
	
	useEffect(() => {
		const timer = setTimeout(() => {
		  setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);	

	  }, []);
	useEffect(() => {
    	window.scrollTo(0, 0);
    }, [pathname]);
	
	return (
      <>
	   {isLoaded ? <LoadSpinner /> :<>
	  <div className="darkbgHome">
	  	<HeaderPage/>
			<Container>							
				<Row>
					<Col lg="10" className="landingbanner">																						
						<div className="hometopvideo">							
							<video loop autoPlay muted={mutedV} src={Videobg} />	
							<span onClick={()=>videoHandler(!mutedV)} className="volumebtn">
								{mutedV ? <FontAwesomeIcon icon={faVolumeMute } /> 
								: 
								<FontAwesomeIcon icon={faVolumeUp } /> }	
							</span>												
							<h2>DAFAx NFT Marketplace - powered by Digital Art Fair</h2>		
							<p>For artists, collectors, blue chip fine art galleries, and all enthusiasts. We excel in bridging 
								physical and digital worlds, now on DAFAx, the best of both worlds all in one trusted place.  </p>								
						</div>
					</Col>
				</Row>
			</Container>
			<Container fluid>
						<div className="opencallstrip" style={{backgroundImage: `url(${Opencallbg}` }}>
							<div className="opencallinnrstrip">
								<Link to="/opencall"><p>open call Applications <br /> are live now</p></Link>
							</div>							
						</div>
			</Container>
			<Container>							
				<Row>
					<Col lg="10" className="landingbanner">	
						<div className="action-box">
							<ActionBox title="Sign Up" image={Actionimg2} linkurl={"/signup"} type= {0}/>
							<ActionBox title="Whitelist Invite" image={Actionimg1} linkurl={"/whitelist"} type= {1}/>
							<ActionBox title="Open Call" image={Actionimg2} linkurl={"/opencall"} type= {0}/>
							<ActionBox title="Artist and Collectors Rights" image={Actionimg1} linkurl={"/artist-collector-rights"} type= {1}/>
						</div>
					</Col>
				</Row>
			</Container>		
			<Container fluid>
				<div className="middleStrip">
					<p>Digital Art Fair Asia Edition <br />
						Hong Kong 2021 Collection 
					</p>
				</div>			
				<HomeSlider slidertitle=""/>
			</Container>
			<Container>
				<Row>
					<Col lg="10" className="lowerbanner">
					<HomeList boxImage={Homelistbg} 
						heading="Powered by Digital Art Fair"
						listText="DAFAx is the official NFT Marketplace powered by Digital Art Fair. Created by a team that is passionate about art and technology, with a goal to provide a well-curated, digital fine art platform."
					/>
					<HomeList boxImage={Homelistbg} 
						heading="Discover groundbreaking artworks"
						listText="We make it simple to discover incredible digital art pieces from all over the world. Our aim is to find true talent and provide a  platform for digital artists to reach a global network of art collectors."
					/>
					<HomeList boxImage={Homelistbg} 
						heading="Curated by the community"
						listText="Each month we will invite respected figures in the digital art community to curate our NFT art drops. We believe in the vision of others and we encourage a range of creational tastes. "
					/>
					</Col>
				</Row>

				<Row>
				<Col lg="10" className="lowerbanner">
					<ListGroup horizontal>
						<ListGroup.Item></ListGroup.Item>
						<ListGroup.Item></ListGroup.Item>
						<ListGroup.Item></ListGroup.Item>
						<ListGroup.Item></ListGroup.Item>
					</ListGroup>
					<h3>Artwork drops coming soon.</h3>	
				</Col>
				</Row>
			</Container>
		</div>
		<FooterPage/>
	  </>}
	  </>
	 ) 
}
export default HomePage;