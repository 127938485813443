import { Image } from 'react-bootstrap';
import './style.scss';
import Spinnerloader from './../static/img/Spinnerloader.svg';
import HeaderPage from './Header';
import FooterPage from "./Footer";

const LoadSpinner = () => (
    <>
        <div className="darkbgcomponent">
         <HeaderPage />
         <div className="lds-spinner"><Image src={Spinnerloader} /></div>
         <FooterPage />
        </div>
    </>
  
);

export default LoadSpinner;