import React ,{useState, useEffect} from 'react';
import './style.scss';
import { useLocation } from "react-router-dom";
import LoadSpinner from './../../components/Loader';
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import { Col, Container, Row, Image } from 'react-bootstrap';
import Collectbg from '../../static/img/collectr.svg';
import {Link} from 'react-router-dom';

const ArtistCollectorRights = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const { pathname } = useLocation();

    useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

  return (
    <>
        {isLoaded ? <LoadSpinner /> : <>
            <div className="darkbgcollectorrights">
                <HeaderPage />
                    <Container fluid className="innercollectbg">
                        <Row>
                            <Col lg={9} className='center-container'>
                                <div className='inviteform'>
                                    <Image  src={Collectbg} alt="collectbg" />
                                    <h2>ARTISTS & COLLECTOR’S <br /> RIGHTS</h2>                                   
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col lg="6">
                                <div className='artistsrights leftside'>
                                    <h3>Artists Rights</h3>
                                    <p>With our experience of years in the art world and art market, we understand fully how important it is that artists are 
                                        protected. In the digital art world, these artworks can often be copied, and resold as a work that does not belong 
                                        to the seller. With our background checks and contracts in place, any creative contributor to the Marketplace can be 
                                        assured that they are justified and have their authenticity proven in the event their work is stolen. 
                                    </p>
                                    <p>When you mint an artwork and it is sold on the platform, these following rights are guaranteed to you by our Smart Contract.</p>
                                    <p className='pink-color'>You retain the copyright and image rights to the artwork.</p>
                                    <p>This protects the original creator, and even if the work is sold, only you can reproduce and make editions and copies of your work. </p>
                                    <p className='pink-color'>Your artwork is cared for. </p>
                                    <p>You set the parameters on how the artwork can be displayed, and collectors will have to adhere to these parameters. </p>
                                    <p className='pink-color'>You will earn royalties from every secondary sale. </p>
                                    <p>On the public blockchain, these sales are known to all, so you as a creator are entitled to a percentage of the sale price, even after the 
                                        initial primary sale, and any profits from secondary sales will go directly into your wallet. 
                                    </p>
                                    <p className='pink-color'>Your collectors have rights too.</p>
                                    <p>To facilitate a healthy digital arts ecosystem, collectors have rights to use, display and resell your artwork, and you can learn 
                                        what they are entitled to here. 
                                    </p>
                                    <p className='pink-color'>These rights are yours, always. </p>
                                    <p>On the blockchain, and on our platform, you can be guaranteed your rights as a creative creator are protected. </p>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className='artistsrights rightside'>
                                    <h3>Collectors Rights</h3>
                                    <p>We understand that to create a robust, and healthy digital art ecosystem, artists and collectors need to be on the same page for the space to flourish. 
                                        Our collectors rights ensures buyers on the platform know what they can and cannot do with the collected artwork. 
                                    </p>
                                    <p className='pink-color'>Authenticity Guaranteed.</p>
                                    <p>You can be assured that the artwork is authentic and from the creator themselves, even in secondary markets.</p>
                                    <p className='pink-color'>Use of work</p>
                                    <p>You may download the original, full resolution files, and display it with the guidelines provided by the artists. </p>
                                    <p className='pink-color'>Loan or lease the artwork. </p>
                                    <p>A temporary loan of the artwork ensures that collectors may grant others the right to display the artwork. </p>
                                    <p className='pink-color'>Resell the artwork. </p>
                                    <p>The collector will be able to resell the artwork on the secondary market, keeping in mind as well that the original creator gets a percentage of the sales. </p>
                                    <p className='pink-color'>These rights are yours, always. </p>
                                    <p>On the blockchain, and on our platform, you can be guaranteed your rights as a collector are yours, until the artwork is sold or transferred. </p>                                    
                                </div>
                            </Col>

                            <div className='bottomtext'>
                                <p>Full Terms of Use can be viewed <Link to="/termsofuse" className='pink-color1'>here.</Link></p>
                            </div>
                        </Row>
                    </Container>                
                </div>
                <FooterPage />
        </>}
    </>
    
  )
}
export default ArtistCollectorRights;