import React  from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form, Image} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import Logo from '../static/DAFALogoWhite.png';

import './style.scss';
function FooterPage (){  
	return (
      <>
	  <div className="footersection">
	  	<Container>
		<Row>
		 <Col lg={6} >
			<div className="footerleftside">
				<Link to={{ pathname: "https://www.digitalartfair.io/" }} className="logo_footer">
					<Image src={Logo} alt= "logo" />
				</Link>
				<div className="footerlinks">
					<ul>
						<li><Link to="/about">About</Link></li>
						<li><Link to="/artist-signup">Artist Sign Up</Link></li>
						<li><Link to="/gallery-signup">Gallries Sign Up</Link></li>
					</ul>
				</div>
				<div className="footerlinks">
					<ul>
						<li><Link to="/signup">Sign Up</Link></li>
						<li><Link to="/whitelist">Whitelist Invite</Link></li>
						<li><Link to="/opencall">Open Call</Link></li>
					</ul>
				</div>
				<div className="footerlinks">
					<ul>
						<li><Link to="/artist-collector-rights">Artist and Collection Rights</Link></li>
						<li><Link to="/termsofuse">Terms of Use</Link></li>
						<li><Link to="/contact">Contact Us</Link></li>
					</ul>
				</div>
			</div>
		 </Col>
		 <Col lg={4} >
			<div className="Emailfooterform">
			<Form>
				<Form.Group className="mb-2" controlId="formBasicEmail">
					<Form.Control type="email" placeholder="Email" />
				</Form.Group>
				<p>Stay in touch with us</p>
				<Button variant="primary" type="submit" className="iconsubmit">
				<FontAwesomeIcon icon={faPaperPlane}/> 
				</Button>
			</Form>
			</div>
		 </Col>
		 <Col lg={2} >
			<div className="socialicons">
				<ul>
					<li><Link to={{ pathname: "https://www.instagram.com/digitalartfairasia/" }} ><FontAwesomeIcon icon={faInstagram }/> </Link></li>
					<li><Link to={{ pathname: "https://twitter.com/dafaxnft" }}> <FontAwesomeIcon icon={faTwitter }/>  </Link></li>
					<li><Link to=""> <FontAwesomeIcon icon={faFacebookF }/>  </Link></li>
					<li><Link to=""> <FontAwesomeIcon icon={faGlobe }/>  </Link></li>
				</ul>
			</div>
		 </Col>
		</Row>
		</Container>
		</div>
	  </>
	 ) 

}
export default FooterPage;