import React  from "react";
import "./style.scss";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";


function MainSignupBox ({imgsrc, title, linkurl}){  	

	return (
      <div className="mainsignbox">
        <Image src={imgsrc} alt="bg-box"></Image> 
        <Link to={linkurl}><p>{title}</p></Link>           
      </div>
	 ) 

}
export default MainSignupBox;