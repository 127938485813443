import React ,{useState, useEffect} from 'react';
import './style.scss';
import { useLocation } from "react-router-dom";
import LoadSpinner from './../../components/Loader';
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import { Col, Container, Row, Image } from 'react-bootstrap';
import Collectbg from '../../static/img/collectr.svg';

const Faq = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const { pathname } = useLocation();

    useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

  return (
    <>
        {isLoaded ? <LoadSpinner /> : <>
            <div className="darkbgcollectorrights faq">
                <HeaderPage />
                    <Container fluid className="innercollectbg">
                        <Row>
                            <Col lg={9} className='center-container'>
                                <div className='inviteform'>
                                    <Image  src={Collectbg} alt="collectbg" />
                                    <h2>Faq</h2>                                   
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className='faqdetails'>
                                    <h3 className='faqtitle'>What is DAFAx</h3>
                                    <p  className='faqtext'>With our experience of years in the art world and art market, we understand fully how important it is that artists are 
                                        protected. In the digital art world, these artworks can often be copied, and resold as a work that does not belong 
                                        to the seller. With our background checks and contracts in place, any creative contributor to the Marketplace can be 
                                        assured that they are justified and have their authenticity proven in the event their work is stolen. 
                                    </p>

                                    <h3 className='faqtitle'>What is NFTs</h3>
                                    <p  className='faqtext'>With our experience of years in the art world and art market, we understand fully how important it is that artists are 
                                        protected. In the digital art world, these artworks can often be copied, and resold as a work that does not belong 
                                        to the seller. With our background checks and contracts in place. 
                                    </p>

                                    <h3 className='faqtitle'>What is DAFAx</h3>
                                    <p  className='faqtext'>With our experience of years in the art world and art market, we understand fully how important it is that artists are 
                                        protected. In the digital art world, these artworks can often be copied, and resold as a work that does not belong 
                                        to the seller. With our background checks and contracts in place, any creative contributor to the Marketplace can be 
                                        assured that they are justified and have their authenticity proven in the event their work is stolen. 
                                    </p>

                                    <h3 className='faqtitle'>What is NFTs</h3>
                                    <p  className='faqtext'>With our experience of years in the art world and art market, we understand fully how important it is that artists are 
                                        protected. In the digital art world, these artworks can often be copied, and resold as a work that does not belong 
                                        to the seller. With our background checks and contracts in place. 
                                    </p>

                                    <h3 className='faqtitle'>What is DAFAx</h3>
                                    <p  className='faqtext'>With our experience of years in the art world and art market, we understand fully how important it is that artists are 
                                        protected. In the digital art world, these artworks can often be copied, and resold as a work that does not belong 
                                        to the seller. With our background checks and contracts in place, any creative contributor to the Marketplace can be 
                                        assured that they are justified and have their authenticity proven in the event their work is stolen. 
                                    </p>

                                    <h3 className='faqtitle'>What is NFTs</h3>
                                    <p  className='faqtext'>With our experience of years in the art world and art market, we understand fully how important it is that artists are 
                                        protected. In the digital art world, these artworks can often be copied, and resold as a work that does not belong 
                                        to the seller. With our background checks and contracts in place. 
                                    </p>

                                    
                                </div>
                            </Col>                          
                        </Row>
                    </Container>                
                </div>
                <FooterPage />
        </>}
    </>
    
  )
}
export default Faq;