import { useState,useEffect }  from "react";
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import {Container, Row, Col,} from 'react-bootstrap';
import "./style.scss";
import LoadSpinner from '../../components/Loader';
import MainSignupBox from './MainSignupBox';
import Signupbg from '../../static/img/signupbg.svg';

function MainSignup (){  

	const [isLoaded, setIsLoaded] = useState(true);
	useEffect(() => {
		const timer = setTimeout(() => {
		  setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);
	  }, []);

	return (
      <>
	  {isLoaded ? <LoadSpinner /> :<>
		<div className="darkbgmainsign">
			<HeaderPage />
				<Container className="innerbox">
                    <h2>Are you a -</h2>
                 <Row>
                     <Col lg={4}> <MainSignupBox imgsrc={Signupbg} title="Artist or Collective " linkurl="/artist-signup"/> </Col>
                     <Col lg={4}> <MainSignupBox imgsrc={Signupbg} title="Gallery or Organisation" linkurl="/gallery-signup"/> </Col>
                     <Col lg={4}> <MainSignupBox imgsrc={Signupbg} title="User or Collector" linkurl="/user-signup"/> </Col>					
				 </Row>
				</Container>	
		</div>	
		<FooterPage/>
	   </>}
	  </>
	 ) 

}
export default MainSignup;