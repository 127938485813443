import React ,{useState, useEffect} from 'react';
import './style.scss';
import { useLocation } from "react-router-dom";
import LoadSpinner from './../../components/Loader';
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import { Col, Container, Row ,Image } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Bgright from '../../static/img/opencllbg.svg'

const OpenCall = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const { pathname } = useLocation();

    useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]); 

  return (
    <>
        {isLoaded ? <LoadSpinner /> : <>
            <div className="darkbgopencall">
                <HeaderPage />
                    <Container className="innerbg">
                        <Row>
                            <Col lg={6} className='left-container'>
                                <div className='opencalldata'>
                                    <h2>Open Calls</h2>
                                    <p>To give back to our community, we invite artists to submit proposals and artworks to be a part of our historic first drop on DAFA<span>x</span>. These proposals and artworks will also be considered for our Pioneer Zone, 
                                        as part of our prestigious physical event Hong Kong Edition in September 2022. </p>
                                    <p>If you would like to be a guest curator, or if you are a gallery or community run project that wishes to feature artists on our marketplace, please get in touch. </p>
                                    
                                    <div className="signupbtn">
                                        <Link to="/signup">Sign Up</Link>
                                    </div>
                                    
                                </div>
                            </Col>
                            <Col lg={6} className='right-container'>
                                <div className='rightimage'>
                                   <Image src={Bgright} alt="background" />
                                </div>
                            </Col>
                        </Row>
                    </Container>                
                </div>
                <FooterPage />
        </>}
    </>
    
  )
}
export default OpenCall;