import React ,{useState, useEffect} from 'react';
import './style.scss';
import { useLocation } from "react-router-dom";
import LoadSpinner from './../../components/Loader';
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import { Col, Container, Row, Form, Button } from 'react-bootstrap';

const WhiteList = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const { pathname } = useLocation();

    useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

  return (
    <>
        {isLoaded ? <LoadSpinner /> : <>
            <div className="darkbgwhitelist">
                <HeaderPage />
                    <Container fluid className="innerbg">
                        <Row>
                            <Col lg={6} className='center-container'>
                                <div className='inviteform'>
                                    <h2>Whitelist Invitation</h2>
                                    <p>To our previous collectors, type in the special code below to receive the whitelist invitation:</p>

                                    <Form className='whitelistform'>
                                        <Form.Group>                                    
                                            <Form.Control type="name" placeholder="" />
                                        </Form.Group>
                                        <Button type="submit">Submit</Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>                
                </div>
                <FooterPage />
        </>}
    </>
    
  )
}
export default WhiteList;