import { useState,useEffect }  from "react";
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import {Container, Row, Col,Form,Button} from 'react-bootstrap';
import "./style.scss";
import LoadSpinner from './../../components/Loader';
import { useLocation } from 'react-router-dom';
import { functionService } from "../../api/function";
import { useHistory } from "react-router-dom";
function Contactus (){  

	const [isLoaded, setIsLoaded] = useState(true);
	const [error, setError] = useState({});
	const[disabled,setDisabled]=useState(false);
	let history = useHistory();
	const [message, setMessage] = useState({status:false,message:''});
	let postData = {
		"email_address" : '',
		"status" : "subscribed",
		"merge_fields" : {
			"FNAME": "",
			"PHONE": "",
			"MSG": ''
		}
	};
	const[formData, setFormData] = useState(postData);
	const { pathname } = useLocation();
	useEffect(() => {
		const timer = setTimeout(() => {
		  setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);
	  }, []);
	  useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);

	  const handleSubmit = async() =>{
		setMessage({status:false,message:''});
		let data = functionService.validateError(formData);
		if(Object.keys(data).filter(x => data[x] === true).length > 0){ setError(data); return;} else{ setError({})} 		
		if(disabled === true){ return false;}
		setDisabled(true);
		setIsLoaded(true);
		let res = await functionService.postData(formData,"contact.php");
		setMessage(res);		
		if(res.status === true){ setFormData(postData);}
		setIsLoaded(false);
		setDisabled(false);
		history.push("/thankyou");
	}
	const handleChange = (e,key)=>{
		let array = {...formData};
		array['merge_fields'][key] = e.target.value;
		setFormData(array);
	}
	return (
      <>
	  {isLoaded ? <LoadSpinner /> :<>
		<div className="darkcontactBlack">
			<HeaderPage />
				<Container>
                 <Row className="contactMain">
                  <h2 className="contactdesc">Contact Us</h2>
					<Col lg='5'>						
						<Form>
						  <Form.Group>
							   <Form.Label>Name</Form.Label>
							  <Form.Control type="name" placeholder="" value={formData['merge_fields']['FNAME']} onChange={(e)=>handleChange(e,'FNAME')}/>
							  {(error && error.hasOwnProperty('FNAME')) && <p className="error errcont">Please fill required field!</p>}
						  </Form.Group>

						  <Form.Group>
							   <Form.Label>Email</Form.Label> 
							  <Form.Control type="email" value={formData['email_address']} placeholder="abc@xyz.com" 
							  onChange={(e)=>setFormData({...formData,email_address:e.target.value})}
							  />
							  {(error && error.hasOwnProperty('email_address')) && <p className="error errcont">Please fill required field!</p>}
						  </Form.Group>

						  <Form.Group>
						   <Form.Label>Phone Number</Form.Label> 
						       <span>Optional</span>
							  <Form.Control type="text" value={formData['merge_fields']['PHONE']} placeholder="(222) 222-2222" onChange={(e)=>handleChange(e,'PHONE')}/>
							  {(error && error.hasOwnProperty('PHONE')) && <p className="error errcont">Please fill required field!</p>}
						  </Form.Group>
						  <Form.Group>
						   <Form.Label>Message</Form.Label> 
							  <Form.Control value={formData['merge_fields']['MSG']}  as="textarea" rows={10} placeholder="Tell what’s on your mind." onChange={(e)=>handleChange(e,'MSG')}/>
							  {(error && error.hasOwnProperty('MSG')) && <p className="error errconttext">Please fill required field!</p>}
						  </Form.Group>
						  
						  <Button variant="primary" type="button" className='smallBtn' onClick={handleSubmit}>
						  	SEND
						  </Button>							
						</Form>
						{message.message && <p className={message.status === false ? "errorMessage":"successMessage"}>{message.message}</p>}
					</Col>
				 </Row>
				</Container>	
		</div>	
		<FooterPage/>
	   </>}
	  </>
	 ) 

}
export default Contactus;