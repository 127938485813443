import React  from "react";
import "./style.scss";
import  "./../../static/bg-sls.png";
import {Image} from 'react-bootstrap';

function HomeList ({boxImage, heading, listText, classs=''}){  
	return (
      <>
           <div className="homemainlisitng">
                <div className={`homelist_heading ${classs}`}>
                    <Image src={boxImage} alt='boximg'></Image>                    
                </div>
                <div className="homelistrightside" >
                    <h2> {heading} </h2>
                    <p> {listText} </p>
                </div>
           </div>
	  </>
	 ) 

}
export default HomeList;