import Carousel from "react-multi-carousel";
import "./style.scss";
import {Link} from 'react-router-dom';
import "react-multi-carousel/lib/styles.css";
function CollectionSlider ({slidertitle, SliderIconFilter='', SliderIconLink=''}){  


const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      paritialVisibilityGutter: 28
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30
    }
  };
  const collections = [
    {
      title: "Infinty Choi Hung Esate",
      subtitle: "SurrealHK",
      prize:"Sold for 3 ETH",
      media: require("../../static/img/SURREALHK.png").default,
      url:"https://app.refinable.com/item/0xe9ee4511839ec91ad48f90a098e5cf9c40be8769/52"

    },
    {
      title: "Digital ASCENSION",
      subtitle: "Mad Dog Jones and Andy Knives",
      prize:"Sold for 75.0 ETH",
      media:require("../../static/img/MAD-DOG.png").default,
      url:"https://digitalartfair.maddogjones.com/"
    },
    {
      title: "PORCELAIN//",
      subtitle: "Fvckrender", 
      prize:"Sold for 36.9 ETH",
      media:require("../../static/img/FVCKRENDER - PORCELAIN.png").default,
      url:"https://digitalartfair.fvckrenderverse.com/"
    },
    {
      title: "machine Hallucinations - Space:Metaverse",
      subtitle: "Refik Anadol",
      prize:"Sold for 1050 ETH",
      media:require("../../static/img/REFIK-MACHINE .jpeg").default,
      url:"https://www.sothebys.com/en/buy/auction/2021/machine-hallucinations-space-metaverse?locale=en&lotFilter=AllLots"
    },
    {
      title: "Lost In Time",
      subtitle: "Stfeyes",
      prize:"Sold for 1.5 ETH",
      media:require("../../static/img/LOST-IN-TIME.png").default,
      url:"https://app.refinable.com/item/0xd7b134bcec26394e4bc469a5227c9a2b0c8d98eb/117/6167c7d453a5526583138431"
    },
    {
      title: "On Becoming a Lucky Human Being",
      subtitle: "Nick Teeple",
      prize:"Sold for 3 ETH",
      media:require("../../static/img/LUCKY-HUMAN-BEING.png").default,
      url:"https://app.refinable.com/item/0xd7b134bcec26394e4bc469a5227c9a2b0c8d98eb/215/6153eac3cfb62c0012381d22"
    },
    {
      title: "COLLECTED MEMORIES 001",
      subtitle: "Derry Ainsworth",
      prize:"Sold for 4 ETH",
      media:require("../../static/img/COLLECTED- MEMORIES.png").default,
      url:""
    },
    {
      title: "SEPUKKU",
      subtitle: "Pejac",
      prize:"Sold for 25.0 ETH",
      media:require("../../static/img/PEJAC - SEPUKKU.jpg").default,
      url:""
    },
    {
      title: "Waterfall of Language",
      subtitle: "MC Yan",
      prize:"Sold for 3.33 ETH",
      media:require("../../static/img/WATERFALL.png").default,
      url:""
    },
    {
      title: "Tales of The Moment",
      subtitle: "King of Kowloon",
      prize:"Sold for 1 ETH",
      media:require("../../static/img/TALES-OF-THE-MOMENT.png").default,
      url:""
    },
   
  ];


	return (
      <>
      <div className="slidermianCollection1">        
		  <Carousel
        ssr
        partialVisbile        
        itemClass="image-item"
        responsive={responsive}
        // infinite={true}
        // autoPlay={true}
        // autoPlaySpeed={3000}
        // transitionDuration={500}
        >
        {collections.map((obj,index) => {
            return (  
              <>      
              <Link  to={{ pathname: obj.url }} target="_blank" rel="noreferrer">              
                <img
                  draggable={false}
                  style={{ width: "100%", height: "100%", padding: "15px", margin:"0px 0px", background: "#efefef" }}
                  src={obj.media} 
                  alt="Slider-images"
                /> 
              </Link> 
              <div className="slider-text">  
              <Link  to={{ pathname: obj.url }} target="_blank" rel="noreferrer"><p className="slideData">{obj.title}</p></Link>
              <Link  to={{ pathname: obj.url }} target="_blank" rel="noreferrer"><p className="slideData">{obj.subtitle}</p></Link>
              <Link  to={{ pathname: obj.url }} target="_blank" rel="noreferrer"><p className="slideData">{obj.prize}</p></Link>
              </div>
              </>     
            );
        })}
        
      </Carousel>
      {/* <img src={require('https://drive.google.com/drive/u/0/folders/1C7HZXdQo14sVZx-YAN0I7KWR4VStHGT_')} /> */}
    </div>
	  </>
	 ) 

}
export default CollectionSlider;