import React,{useState} from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { UserContext } from './lib/UserContext';
import './App.css';

import HomePage from "./Pages/Home/HomePage";
import About from "./Pages/AboutUs/AboutUs";
import WhiteList from "./Pages/WhiteList/WhiteList";
import OpenCall from "./Pages/OpenCall/OpenCall";
import MainSignUp from "./Pages/MainSignup/MainSIgnup";
import UserSignUp from "./Pages/UserSignup/UserSignUp";
import GallerySignup from "./Pages/GallerySignup/GallerySignup";
import ArtistSignup from "./Pages/ArtistSignup/ArtistSignup";
import TermsofUse from "./Pages/TermsofUse/TermsofUse";
import Contact from "./Pages/Contact/Contact";
import ArtistCollectorRights from "./Pages/ArtistCollectorRights/ArtistCollectorRights";
import ThankYou from "./components/ThankYou";
import Faq from "./Pages/Faq/Faq";

function App() {
	const [loginUser, setLoginUser] = useState(null);
  return (
    <UserContext.Provider value={[loginUser, setLoginUser]}>
    <Router>
      <Switch>
          <Route
          exact
          path="/"
          render={() => <Redirect to="/home" />}
        />
        <PublicRoute path="/home" component={HomePage} />
        <PublicRoute path="/about" component={About} />
        <PublicRoute path="/whitelist" component={WhiteList} /> 
        <PublicRoute path="/opencall" component={OpenCall} /> 
        <PublicRoute path="/user-signup" component={UserSignUp} />
        <PublicRoute path="/signup" component={MainSignUp} />
        <PublicRoute path="/gallery-signup" component={GallerySignup} />
        <PublicRoute path="/artist-signup" component={ArtistSignup} />        
        <PublicRoute path="/contact" component={Contact} />
        <PublicRoute path="/termsofuse" component={TermsofUse} />
        <PublicRoute path="/artist-collector-rights" component={ArtistCollectorRights} />       
        <PublicRoute path="/thankyou" component={ThankYou} />   
        <PublicRoute path="/faq" component={Faq} /> 
       </Switch>
    </Router>
	 </UserContext.Provider>
  );
  
  function PublicRoute({ component, ...rest } : any) {
    return (
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
    );
  }
  // function PrivateRoute({ component, ...rest } : any) {
  //   return (
	// localStorage.getItem('checkLogin') === 'solpulseadmin' ?
  //     <Route
  //       {...rest}
  //       render={props =>
  //        (
  //           React.createElement(component, props)
  //         )
  //       }
  //     />
	//   :
	//   <Redirect to="/login" />
	  
  //   );
  //}
}

export default App;
