import React ,{useState, useEffect} from 'react';
import './style.scss';
import { useLocation } from "react-router-dom";
import LoadSpinner from './../../components/Loader';
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import { Col, Container, Row } from 'react-bootstrap';
import ActionBox from '../Home/ActionBox';
import Actionimg2 from '../../static/img/actionimg2.svg';

const AboutUs = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const { pathname } = useLocation();

    useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

  return (
    <>
        {isLoaded ? <LoadSpinner /> : <>
            <div className="darkbgaboutus">
                <HeaderPage />
                <div className="innerbg">                
                    <Container >
                        <Row>
                            <Col lg={4} className='left-side'>
                                <div className='inviteleft'>
                                      <ActionBox image={Actionimg2} title="Contact Us" type={1} linkurl="/contact"/>                              
                                </div>
                            </Col>
                            <Col lg={4} className='middle-side'>
                                <div className='inviteform'>
                                    <h2>About</h2>
                                    <p>Powered by Digital Art Fair, the leading international art x tech fair dedicated to innovative digital art & NFTs, DAFAx is a 
                                        brand new NFT marketplace in Web 3.0 that features a self-sustaining art 
                                        ecosystem where artists, collectors, dealers and gallerists from around the world can exhibit, sell and collect NFT fine art.
                                    </p>     
                                    <p>Leveraging the extensive network of Digital Art Fair and its community in Asia and beyond, DAFAx NFT 
                                        marketplace will present highly anticipated monthly drops from some of the best Asian and international artists, brands and 
                                        institutions. Selected NFTs will have the chance to be displayed in a highly curated exhibition setting at the physical fair 
                                        of Digital Art Fair globally, establishing a new standard for the international NFT fine art industry.
                                    </p>  
                                    <p>At DAFAx, we make it a priority to provide the best security and protection for our users. Trusted by some of the most important 
                                        artists and collectors in the world, DAFAx is a NFT marketplace where artists’ and buyers’ rights are well protected. 
                                    </p> 
                                    <p>Curated. Collected. Trusted. Here at the DAFAx.</p>                            
                                </div>
                            </Col>
                            <Col lg={4} className='right-side'>
                            <ActionBox image={Actionimg2} title="Faq" type={1} linkurl="/faq"/>
                            </Col>
                        </Row>
                    </Container> 
                </div>               
            </div>
            <FooterPage />
        </>}
    </>
    
  )
}
export default AboutUs;