import React from "react";
import { Row, Navbar, Nav,Container} from 'react-bootstrap';
import './style.scss';
import {Link} from 'react-router-dom';
import Logo from '../static/img/dafaLogo.png';

function HeaderPage (){  
	
		const navtogglehandle= () =>{
			document.body.classList.toggle('bodyhide');
		}
	return (
      <>
	  <div style={{backgroundColor: "#000"}}>
	  <Container >
	 	<Row className="rowheadersection">
			<Navbar expand="lg">
				<Navbar.Brand className="" ><Link to="/home"><img src={Logo} alt="logo"/></Link></Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={navtogglehandle}/>
				<Navbar.Collapse id="basic-navbar-nav" >
				<Nav>				
				<Link className="nav-link" to="/about" >About</Link>	
				<Link className="nav-link" to={{ pathname: "https://www.digitalartfair.io/" }} target="_blank">Art fair</Link>
					<Link className="nav-link" to="/artist-signup" >Artist sign up</Link>
					<Link className="nav-link" to="/gallery-signup" >Gallery sign up</Link>												
					<Link className="btn btn_login" to="" >Connect Wallet</Link>					
				</Nav>
				</Navbar.Collapse>
			</Navbar>		 
		</Row>
		</Container> 
	  </div>
	  </>
	 ) 

}
export default HeaderPage;