import { useState,useEffect }  from "react";
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import {Container, Row, Col,Form,Button} from 'react-bootstrap';
import IconAccount from '../../static/icon-account.svg';
import Iconmail from '../../static/icon-mail.svg';
import Iconpadlock from '../../static/icon-padlock.svg';
import "./style.scss";
import LoadSpinner from '../../components/Loader';

function UserSignup (){  

	const [isLoaded, setIsLoaded] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
		  setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);
	  }, []);

	return (
      <>
	  {isLoaded ? <LoadSpinner /> :<>
		<div className="darkbgusersign">
			<HeaderPage />
				<Container fluid>
                 <Row className="Signup">
					<Col lg='5'>
						<div className='formHead'>
							Create Account
						</div>
						<Form>
						  <Form.Group>
							  <span><img src={IconAccount} alt='icons'/></span>
							  <Form.Control type="name" placeholder="Full Name" />
						  </Form.Group>

						  <Form.Group>
						      <span><img src={Iconmail} alt='icons'/></span>
							  <Form.Control type="email" placeholder="Email" />
						  </Form.Group>

						  <Form.Group>
						       <span><img src={Iconpadlock} alt='icons'/></span>
							  <Form.Control type="password" placeholder="Password" />
						  </Form.Group>
						  <Form.Group>
						      <span><img src={Iconpadlock} alt='icons'/></span>
							  <Form.Control type="password" placeholder="Re-enter Password" />
						  </Form.Group>
						  
						  <Button variant="primary" type="submit">
						  Create Account
						  </Button>

						  <Button variant="primary" type="submit" className="SignupBtn">
						  SIGN UP
						  </Button>

						</Form>
					</Col>
				 </Row>
				</Container>	
		</div>	
		<FooterPage/>
	   </>}
	  </>
	 ) 

}
export default UserSignup;