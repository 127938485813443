import { useState, useEffect } from "react";
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import { Container, Row, Col } from 'react-bootstrap';
import "./style.scss";
import LoadSpinner from './../../components/Loader';
import { useLocation } from "react-router-dom";


function TermsofUse() {

	const [isLoaded, setIsLoaded] = useState(true);
	const { pathname } = useLocation();

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoaded(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return (
		<>
			{isLoaded ? <LoadSpinner /> : <>
				<div className="darkbgterms TermsofUsepage">
					<HeaderPage />
					<Container className='termsMain'>
						<Row>
							<Col lg={'9'}>
								<div className='termsTop'>
									<h2 className='termstitle'>Terms & Conditions</h2>
								</div>
								<div className='termsInner'>
									<div className="termListsection">
										<div className='termslist'>
											<h3>1. Digital Art Fair - Our Company</h3>

											<span> 1.1 Digital Art Fair (Trading as Howards Limited) is registered in Hong Kong, registration number 123xxxx with our registered 
												address D901, 9/f, Shing King Ind. Bld., 45 Kut Shing St, Chai Wan, Hong Kong.
											</span>
											<span>
												1.2 Contact Method Should you wish to contact us. Please email: info@digitalartfair.io
											</span>
											<span>
												1.3 How We Contact Us
												If we have the need to contact you, we shall do so either by telephone or in writing at the email or postal address provided to 
												us in your order, or in previous correspondence, including via Instagram or any other social media outlet you have used to contact 
												us previously.
											</span>
										</div>
										<div className='termslist'>
											<h3>2. Our Contact with you</h3>

											<span>2.1   How we will accept your order. Our acceptance of your order begins when we email you to accept it, at which point a 
												contract will come into existence between you and us.
											</span>
											<span>
												2.2   If we cannot accept your order. If we are unable to accept your order for any reason, we will inform you of this in writing 
												at our earliest convenience. If any money has been exchanged prior to our acceptance of your order, the funds will be returned in 
												full (minus any transactional fees) at our earliest convenience.
											</span>
										</div>

										<div className='termslist'>
											<h3>3. Works</h3>

											<span>3.1   Works may vary slightly from their pictures. The images of the Works on our website, social media platforms or in 
												 brochure are for illustrative purposes only. Although we have made every effort to display the colours and details accurately, 
												 we cannot guarantee that a device’s display of the colours and/or details or a picture in our brochure accurately reflects the 
												 colour and/or details of the Works. The Works may vary slightly from those images, and you accept that the Works may appear differently.
											</span>

											<span>3.2  Making sure your measurements are accurate. If we are providing Works that you have commissioned, you are responsible for 
												ensuring that any measurements provided are correct. Measurements provided by us are accurate to the best of our knowledge but 
												there may be some small variance in paper sizing or framed sizing.
											</span>
											<span>3.3  Condition of the Works. We are not responsible for any defects in design, material and workmanship in relation to the Works where:
											</span>
											<span>3.3.1  the defect arises because you failed to follow our oral or written instructions as to the storage, installation, 
												commissioning, use or maintenance of the Works;
											</span>
											<span>3.3.2  in relation to commissioned Works, the defect arises as a result of following your instructions/specification for such Works; [or]
											</span>
											<span>3.3.3  the defect arises as a result of wilful damage, negligence, or abnormal storage/display conditions;
											</span>
										</div>

										<div className='termslist'>
											<h3>4. Delivery of Work</h3>

											<span>4.1  Delivery Costs. The costs of delivery will be as advised to you in your order and otherwise stated in our invoice. For the 
												avoidance of doubt, you shall be solely liable for any stamp and other similar duties and taxes to which the Works may be subject upon receipt.
											</span>
											<span>
												4.2   When we will provide the Works. We will contact you with an estimated delivery date, within 30 days after the day on which we 
												receive your payment.
											</span>
											<span>
												4.3  We are not responsible for delays outside our control. If our supply of the Works is delayed by an event outside of our control, 
												then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay; we will not be 
												liable for delays caused by the event.
											</span>
											<span>
												4.4   When you become responsible for the Works. The Works will be your responsibility from the time the Works are delivered to you, 
												or a carrier organised by you has collected the Works from us.
											</span>
											<span>
												4.5  When you own the Works. You own the Works once we have received payment in full.
											</span>
											<span>
												4.6  When you missed the Payment. If you do not pay us for the Works when you are supposed to (see clause 7.2) and you still do not 
												make payment within 10 days of us reminding you that payment is due, we may suspend supply of the Works until you have paid us the 
												outstanding amounts, we can also charge you interest on your overdue payments (see clause 7.3).
											</span>
										</div>
										<div className='termslist'>
											<h3>5. Your Rights & the Contract</h3>

											<span>5.1  No Returns. Once the Works have been delivered to you, you do not have a right to change your mind and we will not accept 
												any return of the Works.
											</span>
										</div>
										<div className='termslist'>

											<h3>6. Our Rights to End the Contract </h3>

											<span>6.1  We may end the contract if you break it. We may end the contract for any Work(s) at any time by writing to you if:
											</span>
											<span>
												6.1.1 you do not make any payment to us when it is due, and you still do not make payment within 10 days of us reminding you that 
												payment is due; or
											</span>

											<span>
												6.1.2  you do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to provide the Work(s).
											</span>
											<span>
												6.2  You must compensate us if you break the contract. If we end the contract in the situations set out in clause 6.1 we will 
												not refund any money you have paid in advance for Works, we have not provided, and we may deduct or charge you reasonable compensation 
												for the net costs we will incur as a result of your breaking the contract.
											</span>
										</div>

										<div className='termslist'>
											<h3>7. Price & Payment</h3>
											<span>
												7.1  Price. The price of the Works will be the price indicated to you when you placed your order and otherwise as stated 
												in our invoice(s). You shall be liable to pay VAT at the prevailing rate (if applicable).
											</span>
											<span>
												7.2  When you must pay and how you must pay. You must pay invoices in full in cleared funds [within [10] working days of 
												the invoice date OR within the time specified in such invoice], payment shall be made to such account nominated in writing 
												by us. Where you have commissioned any Works, if agreed, you must pay a deposit of 50% of the price of such Works (Deposit) 
												on, or within [5] working days, of the date of your order and the remaining 50% balance on completion of such Works. For the 
												avoidance of doubt, you are not entitled to a refund of the Deposit [under any circumstances].
											</span>
											<span>
												7.3  We can charge interest if you pay late. If you do not make any payment to us by the due date, we may charge interest 
												to you on the overdue amount at the rate of 3% a year above the base lending rate of RBS from time to time. This interest 
												shall accrue on a daily basis from the due date until the date of actual payment of the overdue amount, whether before or 
												after judgment. You must pay us interest together with any overdue amount.
											</span>
										</div>

										<div className='termslist'>
											<h3>8. Our Responsibility For Loss or Damage Suffered By You  </h3>
											<span>
												8.1  Limitation of Liability. Our maximum liability to you for breach of the contract (including any liability for the acts or 
												omissions of its employees, agents and subcontractors), whether arising in contract, tort (including negligence), misrepresentation 
												or otherwise, shall in no circumstances exceed the price for the Works received by us.
											</span>
											<span>
												8.2   We will have no liability to you for any loss of profit, loss of revenue, loss of business, business interruption, 
												loss of business opportunity, or indirect or consequential loss or damage, in each case, however caused, even if foreseeable.
											</span>
										</div>

										<div className='termslist'>
											<h3>9. Other Important Terms </h3>
											<span>
												9.1  We may transfer this agreement to someone else. We may transfer our rights and obligations under these terms to another organisation. 
												We will always tell you in writing if this happens.
											</span>
											<span>
												9.2  You need our consent to transfer your rights to someone else. You may only transfer your rights or your obligations under these terms 
												to another person if we agree to this in writing.
											</span>
											<span>
												9.3  Nobody else has any rights under this contract (except someone you pass your guarantee on to). This contract is between you and us. 
												No other person shall have any rights to enforce any of its terms.
											</span>

											<span>
												9.4  If a court finds part of this contract illegal, the rest will continue in force. Each of the paragraphs of these terms operates 
												separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.
											</span>
											<span>
												9.5   Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything 
												you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not 
												mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.
											</span>
										</div>
									</div>
								</div>
							</Col>
						</Row>

					</Container>
				</div>
				<FooterPage />
			</>}
		</>
	)
}
export default TermsofUse;