import React from 'react';
import {Link} from 'react-router-dom';
import Image from 'react-bootstrap/Image';

const ActionBox = ({image,title,linkurl,type }) => {

  return (
    <div className="actiondemotext" >
    <Image src={image}/>

    {type === 0 && 
        <Link to={linkurl}><p className='top-action'>{title}</p></Link>
    }
    {type === 1 && 
        <Link to={linkurl}><p className='bottom-action'>{title}</p></Link>
    }
    </div>
  )
}

export default ActionBox;