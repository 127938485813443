import { useState, useEffect } from "react";
import HeaderPage from '../../components/Header';
import FooterPage from "../../components/Footer";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";
import "./style.scss";
import LoadSpinner from '../../components/Loader';
import uploadload from '../../static/img/uploadload.gif';
import { functionService } from "../../api/function";
import { useHistory } from "react-router-dom";
import axios from "axios";
function GallerySignup() {
    const [isLoaded, setIsLoaded] = useState(true);
    const { pathname } = useLocation();
    const [uploadloader, setUploadLoader] = useState(false);
    const [error, setError] = useState({});
	const[disabled,setDisabled]=useState(false);
	const [message, setMessage] = useState({status:false,message:''});
    let history = useHistory();
	let postData = {
		"email_address" : '',
		"status" : "subscribed",
		"merge_fields" : {
			"FNAME": "",
            "GNAME":'',
			"LOCATION": "",
			"GWORK": '',
            "WEB":'',
            "MEDIA":'',
            "MSG":'',
            'IMG':''
		}
	};
	const[formData, setFormData] = useState(postData);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoaded(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

    function handleFileChange(event) {        
        setUploadLoader(true) 
        const uploadData =  new FormData();
        uploadData.append('image', event.target.files[0]);
        let url = "https://www.artfairapi.startdesigns.com/upload.php";
        axios.post(url, uploadData, {
        })
        .then(res => {
            if(res.data.error === false){
                let array = {...formData};
                array['merge_fields']['IMG'] = res.data.url;
                setFormData(array);
            }
            setUploadLoader(false) 
            console.log(res.data);
        })
        
    }
    
      const handleSubmit = async() =>{
		setMessage({status:false,message:''});
		let data = functionService.validateError(formData);
		if(Object.keys(data).filter(x => data[x] === true).length > 0){ setError(data); return;} else{ setError({})} 		
		if(disabled === true){ return false;}
		setDisabled(true);
		setIsLoaded(true);
		let res = await functionService.postData(formData,"gallery.php");
		setMessage(res);		
		if(res.status === true){ setFormData(postData);}
		setIsLoaded(false);
		setDisabled(false);
        history.push("/thankyou");
	}
	const handleChange = (e,key)=>{
		let array = {...formData};
		array['merge_fields'][key] = e.target.value;
		setFormData(array);
	}  
   
    return (
        <>
            {isLoaded ? <LoadSpinner /> : <>
                <div className="darkgallerysign">
                {uploadloader && <>
                <div className="uploadLoader">
                    <div className="uploadinner">
                    <img src={uploadload} alt="loading..."></img>
                    <p >Be patience, we are uploading your image...</p>
                    </div>
                </div>
                </>}
                    <HeaderPage />
                    <Container>
                        <Form>
                            <Row className="Signup">
                                <div className='formHead'>
                                    Galleries Sign Up
                                </div>

                                <Col lg="4" className='noformbgleft'>
                                    <Form.Group>
                                        <Form.Control type="name" placeholder="Gallery Name" 
                                        value={formData['merge_fields']['GNAME']} onChange={(e)=>handleChange(e,'GNAME')}
                                        />
                                        {(error && error.hasOwnProperty('GNAME')) && <p className="error">Please fill required field!</p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Control type="email" placeholder="Email" 
                                        value={formData['email_address']} 
                                        onChange={(e)=>setFormData({...formData,email_address:e.target.value})}
                                        />
                                        {(error && error.hasOwnProperty('email_address')) && <p className="error">Please fill required field!</p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Control type="name" placeholder="Name of Gallery Representative" 
                                        value={formData['merge_fields']['FNAME']} onChange={(e)=>handleChange(e,'FNAME')}
                                        />
                                        {(error && error.hasOwnProperty('FNAME')) && <p className="error">Please fill required field!</p>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control type="name" placeholder="Location" 
                                        value={formData['merge_fields']['LOCATION']} onChange={(e)=>handleChange(e,'LOCATION')}
                                        />
                                        {(error && error.hasOwnProperty('LOCATION')) && <p className="error">Please fill required field!</p>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control type="name" placeholder="Has your gallery worked with NFTs before?" 
                                        value={formData['merge_fields']['GWORK']} onChange={(e)=>handleChange(e,'GWORK')}
                                        />
                                        {(error && error.hasOwnProperty('GWORK')) && <p className="error">Please fill required field!</p>}
                                    </Form.Group>
                                </Col>
                                <Col lg="4" className='formbg'>
                                    <Form.Group>
                                        <Form.Control type="name" placeholder="Website" 
                                        value={formData['merge_fields']['WEB']} onChange={(e)=>handleChange(e,'WEB')}
                                        />
                                        {(error && error.hasOwnProperty('WEB')) && <p className="error">Please fill required field!</p>}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Control type="name" placeholder="Social Media Links" 
                                        value={formData['merge_fields']['MEDIA']} onChange={(e)=>handleChange(e,'MEDIA')}
                                        />
                                        {(error && error.hasOwnProperty('MEDIA')) && <p className="error">Please fill required field!</p>}
                                    </Form.Group>

                                    <Form.Group className="uploadgroup">                                        
                                        <Form.Control as="textarea" value={formData['merge_fields']['IMG']} readOnly rows={5} placeholder="Reference Artworks (5 examples, 15mb limit)" className="uploads" />
                                        <Button className="uploadBtn">
                                            Upload
                                            <Form.Control type="file" className="upload-data" onChange={handleFileChange}/>
                                        </Button>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control as="textarea" rows={15} placeholder="Gallery Description (500 words)" 
                                        value={formData['merge_fields']['MSG']} onChange={(e)=>handleChange(e,'MSG')}
                                        />
                                        {(error && error.hasOwnProperty('MSG')) && <p className="errorTextarea">Please fill required field!</p>}
                                    </Form.Group>

                                </Col>
                                <Col lg="4" className='noformbgright'>
                                    <Button variant="primary" type="button" onClick={handleSubmit}>
                                        <FontAwesomeIcon icon={faArrowRight} />  Send application
                                    </Button>

                                    <Button variant="primary" type="button" className="SignupBtn">
                                        SIGN UP
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        {message.message && <p className={message.status === false ? "errorMessage":"successMessage"}>{message.message}</p>}
                    </Container>
                </div>
                <FooterPage />                
            </>}
        </>
    )

}
export default GallerySignup;