import HeaderPage from './Header';
import FooterPage from "./Footer";
import {Container, Row } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import "./style.scss";


function ThankYou (){ 	
    const { pathname } = useLocation();
    useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return (
      <>	  
		<div className="darkbgthankyou">
			<HeaderPage />
				<Container fluid className="thank-container">
                 <Row className="thankyoudata">
					<h2>You’re all set! </h2>
                    <h3>Our team will get in touch with you shortly. </h3>
                    <Link to="/">BACK TO HOME</Link>
				 </Row>
				</Container>	
		</div>	
		<FooterPage/>
	   </>	  
	 ) 

}
export default ThankYou;